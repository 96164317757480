.footer_container {
    display: flex;
    gap: 10px;
    color: white;

    .link_item{
        display: flex;
        line-height: 20px;
    }

    .beianImg {
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-right: 5px;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}