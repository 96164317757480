@import './style/mixins';
@import './style/variables';

* {
    margin: 0;
    padding: 0;
    color: $global_text_color;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

.app {
    width: 100vw;
    height: 100vh;
    background-color: $global_bg;

    display: flex;
    flex-direction: column;

    .app_header {
        height: 6vh;
        background-color: $global_header_bg;
    }

    .app_main {
        flex: 1;
        @include f_jc_ac(column);
        gap: 8px;
    }

    .app_footer {
        height: 8vh;
        background-color: $global_footer_bg;
        @include f_jc_ac();
    }


}

.app_main_img-box {
    width: 200px;
    height: 200px;

    border-radius: 100%;
    overflow: hidden;

    img {
        -webkit-user-drag: none;
    }
}

.app_main_link-box {
    display: flex;
    gap: 5px;

    &:hover {
        color: red;
        cursor: pointer;
    }
}

// 修改antd的样式
.ant-modal-content {
    background-color: blue;
    box-shadow: none;
}