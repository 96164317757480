.header_container {
    width: 80%;
    height: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;

    .header_logo {
        font-weight: 700;
    }

    .header_nav{
        display: flex;   
        justify-content: flex-end;
        gap: 20px;
    }
}

.header_nav_item{
    &:hover{
        cursor: pointer;
        font-weight: 700;
    }
}